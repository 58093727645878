const FloorPlanData = [
    {
        id:1,
        cover1: require("../../Assets/FloorPlan/floor-1.jpg"),
        alttag1:"grandthum bhutani",
        cover2: require("../../Assets/FloorPlan/floor-2.jpg"),
        alttag2:"grandthum bhutani group",
    },
    {
        id:1,
        cover1: require("../../Assets/FloorPlan/floor-3.jpg"),
        alttag1:"grandthum bhutani",
    },
    
]

export default FloorPlanData;